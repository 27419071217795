export function setupDropdowns() {
  const dropdowns = document.querySelectorAll('.desktop-dropdown');

  dropdowns.forEach((dropdown) => {
    const summary = dropdown.querySelector('summary');
    summary.addEventListener('click', (event) => {
      event.preventDefault();
      const clickedIndex = summary.getAttribute('data-index');

      dropdowns.forEach((otherDropdown) => {
        const otherSummary = otherDropdown.querySelector('summary');
        const otherIndex = otherSummary.getAttribute('data-index');

        if (otherIndex !== clickedIndex) {
          otherDropdown.removeAttribute('open');
        }
      });

      dropdown.toggleAttribute('open');
    });
  });

  document.addEventListener('click', (event) => {
    if (!event?.target?.closest('.desktop-dropdown')) {
      dropdowns.forEach((dropdown) => {
        dropdown.removeAttribute('open');
      });
    }
  });
}
